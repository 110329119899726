import BaseBean from "@/utils/BaseBean";

export interface IDraftPiListDataObj {
    utilInst:DraftPiListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class DraftPiListUtil extends BaseBean{
    public dataObj:IDraftPiListDataObj

    constructor(proxy:any,dataObj:IDraftPiListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}