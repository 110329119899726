import PiCard from '../pi/PiCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance} from 'vue';
import DraftPiListUtil ,{IDraftPiListDataObj} from './draftPiListUtil';

export default defineComponent ({
    name: 'DraftPiList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IDraftPiListDataObj=reactive<IDraftPiListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'PI草稿箱',
                queryParam: {},
                cardFrom:'DraftPiList',
                modelComp: PiCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/draftPi/pageData')
            },
            otherParams:{},
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new DraftPiListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'flag'==params.comboId){
                    return [{value:0,label:'未发布'},{value:1,label:'已发布'}];
                }
            }
        })

        return{
            ...toRefs(dataObj),comboSelect
        }
    }
});